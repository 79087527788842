import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Image, Icon, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdSignalCellular4Bar } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | Vinyl Haven
			</title>
			<meta name={"description"} content={"Dijitalin hakim olduğu bir dünyada Vinyl Haven, hem ses tutkunları hem de müzik meraklıları için bir yol gösterici olarak duruyor. "} />
			<meta property={"og:title"} content={"Ana Sayfa | Vinyl Haven"} />
			<meta property={"og:description"} content={"Dijitalin hakim olduğu bir dünyada Vinyl Haven, hem ses tutkunları hem de müzik meraklıları için bir yol gösterici olarak duruyor. "} />
			<meta property={"og:image"} content={"https://ottomanodyssey.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" md-padding="40px 0 40px 0" quarkly-title="About-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 48px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 48px 24px 24px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 40px 0px"
				md-align-items="flex-start"
				md-padding="24px 0px 24px 0px"
			>
				<Text margin="0px 0px 24px 0px" color="--dark" font="--base" lg-text-align="center">
					Hoş Geldiniz
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Vinyl Haven
				</Text>
				<Box
					display="flex"
					flex-direction="column"
					margin="0px 0px 24px 0px"
					grid-gap="16px"
					lg-flex-direction="row"
					lg-display="flex"
					lg-grid-gap="24px"
					sm-flex-direction="column"
					sm-display="flex"
					sm-grid-gap="12px"
				>
					<Text
						color="--dark"
						font="--base"
						lg-text-align="left"
						lg-margin="0px 0px 0px 0px"
						display="flex"
						margin="0px 0px 0px 0px"
					>
						Dijitalin hakim olduğu bir dünyada Vinyl Haven, hem ses tutkunları hem de müzik meraklıları için bir yol gösterici olarak duruyor. Raflarımız, zamansız klasiklerden çağdaş hitlere kadar titizlikle seçilmiş vinil plaklardan oluşuyor. Vinyl Haven sadece bir mağaza değil, müziğin hayat bulduğu ve sizi sesi en otantik haliyle deneyimlemeye davet eden bir mabettir.
					</Text>
				</Box>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="9px 24px 10px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					border-radius="6px"
					letter-spacing="0.5px"
				>
					Kişiler
				</Link>
			</Box>
			<Box
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="16px"
				sm-grid-gap="16px"
				width="60%"
				lg-width="100%"
			>
				<Image src="https://ottomanodyssey.live/img/1.jpg" border-radius="24px" object-fit="cover" width="100%" />
				<Image
					src="https://ottomanodyssey.live/img/2.jpg"
					border-radius="24px"
					object-fit="cover"
					width="100%"
					margin="60px 0px 0px 0px"
				/>
				<Box
					grid-column="1 / 3"
					display="flex"
					margin="20px 0px 0px 0px"
					flex-direction="column"
					lg-margin="0px 0px 0px 0px"
				>
					<Icon
						category="md"
						icon={MdSignalCellular4Bar}
						size="24px"
						margin="0px 0px -2px 20px"
						color="--color-darkL1"
					/>
					<Box background="--color-darkL1" padding="24px 32px 24px 32px" max-width="450px" border-radius="6px">
						<Text margin="0px 0px 8px 0px" color="--light" font="--headline3" lg-text-align="left">
							Vinyl Haven
						</Text>
						<Text margin="0px 0px 0px 0px" color="--lightD1" font="--base" lg-text-align="left">
							Müziğin Sizdeki Eşiyle Buluştuğu Yer
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 80px 0" quarkly-title="Statistics-6">
			<Box min-width="100px" min-height="100px" margin="0px 40% 0px 0px" lg-margin="0px 0 0px 0px">
				<Text margin="0px 0px 0px 0px" font="normal 700 40px/1.2 --fontFamily-sans" sm-font="normal 700 34px/1.2 --fontFamily-sans">
					Vinyl Haven'da Müziğin Ruhunu Keşfedin
				</Text>
				<Text margin="15px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="--greyD2">
					Vinyl Haven'a adım atın ve her bir vinil plağın melodiler, duygular ve hikayelerden oluşan bir evreni barındırdığı bir diyara ışınlanın. Türleri, dönemleri ve kültürleri kapsayan koleksiyonumuz, ruhu ateşleyen ve zihne ilham veren sonik bir yolculuk sunuyor. Burada müzik sadece duyulmakla kalmaz, hissedilir, görülür ve derinlemesine deneyimlenir.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 36px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Neden Vinyl Haven'ı Seçmelisiniz?
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Zengin Bir Ses Gobleni
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Çok çeşitli koleksiyonumuz, zevkiniz ne olursa olsun, yankı uyandıran bir şey bulmanızı sağlar. Rock efsanelerinden caz ikonlarına, indie mücevherlerden klasik başyapıtlara kadar Vinyl Haven işitsel bir hazine.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Vinyl Haven Deneyimi
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Sadece plakların ötesinde, seçiminizi yapmadan önce kendinizi vinilin sıcak, analog sesine kaptırabileceğiniz dinleme istasyonları sunuyoruz. Bu sadece bir işlem değil, bir bağlantı oluşturmakla ilgilidir.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Bilgili Personel, Tutkulu Topluluk
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Personelimiz sadece çalışan değil, yeni müzikler keşfetmenize veya nadir bulunan albümleri bulmanıza yardımcı olmaya kendini adamış plak meraklılarıdır. Müziğin ve anıların yapıldığı ve paylaşıldığı bir topluluğa katılın.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" padding="65px 0 65px 0" quarkly-title="Images-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
				>
					<Image
						left={0}
						min-height="100%"
						top={0}
						right={0}
						bottom={0}
						src="https://ottomanodyssey.live/img/3.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://ottomanodyssey.live/img/4.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						position="absolute"
						display="block"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						src="https://ottomanodyssey.live/img/5.jpg"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="25%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-padding="0px 16px 16px 16px"
			/>
		</Section>
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-5">
			<Text margin="0px 0px 25px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1">
				Özel Baskılar ve Etkinlikler
			</Text>
			<Text margin="0px 0px 0px 0px" font="normal 300 24px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
				Sınırlı sayıda üretilen baskılara ve müzikseverlerin albüm lansmanlarını, sanatçı konuşmalarını ve plak dinleme partilerini kutlamak için bir araya geldiği üyelere özel etkinliklere erişim kazanın.
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});